@font-face {
	font-family: 'Xfinity Brown TT';
	src: url('~src/assets/fonts/XfinityBrownTT-Regular.woff2') format('woff2'),
		url('~src/assets/fonts/XfinityBrownTT-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Xfinity Brown TT';
	src: url('~src/assets/fonts/XfinityBrownTT-Bold.woff2') format('woff2'),
		url('~src/assets/fonts/XfinityBrownTT-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Xfinity Brown TT';
	src: url('~src/assets/fonts/XfinityBrownTT-Light.woff2') format('woff2'),
		url('~src/assets/fonts/XfinityBrownTT-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
