@font-face {
  font-family: Xfinity Brown TT;
  src: url("XfinityBrownTT-Regular.63fd10c7.woff2") format("woff2"), url("XfinityBrownTT-Regular.579e46cb.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Xfinity Brown TT;
  src: url("XfinityBrownTT-Bold.8b67d171.woff2") format("woff2"), url("XfinityBrownTT-Bold.a4a20711.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Xfinity Brown TT;
  src: url("XfinityBrownTT-Light.a0f3ffa3.woff2") format("woff2"), url("XfinityBrownTT-Light.75c7a62f.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

:root {
  --window-height: 100vh;
}

:root.basketball .soccer, :root.basketball .football {
  display: none;
}

:root.basketball .soccer p, :root.basketball .football p {
  letter-spacing: -.5px;
  text-align: center;
  color: #fff;
  font-family: Xfinity Brown TT, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
}

:root.basketball .soccer a, :root.basketball .football a {
  text-align: center;
  color: #fff;
  cursor: pointer;
  margin: 32px auto 36px;
  font-family: Xfinity Brown TT, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: block;
}

:root.basketball .soccer-icon, :root.basketball .football-icon {
  display: none;
}

:root.basketball .basketball-icon {
  width: 40px;
  position: absolute;
  top: -20px;
  left: 20px;
}

:root.basketball .basketball-icon img {
  width: 100%;
  height: 100%;
}

:root.soccer .basketball, :root.soccer .football {
  display: none;
}

:root.soccer .basketball p, :root.soccer .football p {
  letter-spacing: -.5px;
  text-align: center;
  color: #fff;
  font-family: Xfinity Brown TT, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
}

:root.soccer .basketball a, :root.soccer .football a {
  text-align: center;
  color: #fff;
  cursor: pointer;
  margin: 32px auto 36px;
  font-family: Xfinity Brown TT, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: block;
}

:root.soccer .basketball-icon, :root.soccer .football-icon {
  display: none;
}

:root.soccer .soccer-icon {
  width: 40px;
  position: absolute;
  top: -20px;
  left: 20px;
}

:root.soccer .soccer-icon img {
  width: 100%;
  height: 100%;
}

:root.football .soccer, :root.football .basketball {
  display: none;
}

:root.football .soccer p, :root.football .basketball p {
  letter-spacing: -.5px;
  text-align: center;
  color: #fff;
  font-family: Xfinity Brown TT, sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
}

:root.football .soccer a, :root.football .basketball a {
  text-align: center;
  color: #fff;
  cursor: pointer;
  margin: 32px auto 36px;
  font-family: Xfinity Brown TT, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: block;
}

:root.football .soccer-icon, :root.football .basketball-icon {
  display: none;
}

:root.football .football-icon {
  width: 40px;
  position: absolute;
  top: -20px;
  left: 20px;
}

:root.football .football-icon img {
  width: 100%;
  height: 100%;
}

:root.basketball, :root .basketballScope, :root.football, :root .footballScope, :root.soccer, :root .soccerScope {
  --background-image: url("purple-gradient-mobile.f3ba42fb.png");
  --field-background: var(--dark-purple);
  --field-outline: var(--light-purple);
  --leaderboard-offers-background: var(--dark-purple);
  --leaderboard-offers-color: white;
}

:root.basketball, :root .basketballScope {
  --logo: url("we-all-ball.cefa3841.png");
  --ready-ball: url("ball-ar.c9c88184.png");
  --ready-ball-offset: calc(-1 * min(100vw, 420px) * .15);
  --leaderboard-ball: url("basketball.9e7b3f19.png");
  --leaderboard-half-circle: url("half-circle.3fd1c59c.png");
}

:root.football, :root .footballScope {
  --logo: url("kick-it.b9523277.png");
  --ready-ball: url("ball-ar.2de16b92.png");
  --ready-ball-offset: 0px;
  --leaderboard-ball: url("football.430cb4c5.png");
  --leaderboard-half-circle: url("half-circle.bf394047.png");
}

:root.soccer, :root .soccerScope {
  --logo: url("goals-logo.3b5ca6f4.png");
  --ready-ball: url("ball-ar.6b04b7df.png");
  --ready-ball-offset: calc(-1 * min(100vw, 420px) * .15);
  --leaderboard-ball: url("soccer-ball.da35fb18.png");
  --leaderboard-half-circle: url("half-circle.bf394047.png");
}

.Field-Input input {
  appearance: none;
  height: 25px;
  border: 1px solid;
}

.Field-Input input[type="text"], .Field-Input input [type="select"], .Field-Input input[type="number"] {
  border-radius: 8px;
  padding: 12px 16px 13px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  outline-style: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

body {
  background: var(--background-image);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

:root {
  --black: #000;
  --white: #fff;
  --blue: #1f69ff;
  --red: #e6004d;
  --orange: #e64f00;
  --purple: #714cf6;
  --dark-purple: #6138f5;
  --light-purple: #b29efa;
  --pastel-purple: #c1b1fb;
  --light-green: #02c684;
  --dark-green: #008558;
  --dark-light-purple: color. mix(#000, #6138f5, 85%);
  --light-light-purple: color. mix(#fff, #6138f5, 20%);
  --light-dark-green: color. mix(#fff, #008558, 50%);
  --light-dark-purple: color. mix(#fff, #6138f5, 50%);
  --dark-pastel-purple: color. mix(#000, #c1b1fb, 85%);
  --dark-dark-purple: color. mix(#000, #6138f5, 85%);
  --light-blue: #86adfc;
  --xfinity-purple: #5a23b9;
}

.c4q5hW_root {
  color: var(--white);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Xfinity Brown TT, sans-serif;
  display: flex;
  position: absolute;
  inset: 0;
}

.c4q5hW_root h1 {
  font-weight: bold;
}

.c4q5hW_root p, .c4q5hW_root button {
  margin-top: 1rem;
}

:root {
  --black: #000;
  --white: #fff;
  --blue: #1f69ff;
  --red: #e6004d;
  --orange: #e64f00;
  --purple: #714cf6;
  --dark-purple: #6138f5;
  --light-purple: #b29efa;
  --pastel-purple: #c1b1fb;
  --light-green: #02c684;
  --dark-green: #008558;
  --dark-light-purple: color. mix(#000, #6138f5, 85%);
  --light-light-purple: color. mix(#fff, #6138f5, 20%);
  --light-dark-green: color. mix(#fff, #008558, 50%);
  --light-dark-purple: color. mix(#fff, #6138f5, 50%);
  --dark-pastel-purple: color. mix(#000, #c1b1fb, 85%);
  --dark-dark-purple: color. mix(#000, #6138f5, 85%);
  --light-blue: #86adfc;
  --xfinity-purple: #5a23b9;
}

.gMH8yW_root {
  font-size: inherit;
  color: inherit;
  background-color: #0000;
  border: none;
}

.gMH8yW_root.gMH8yW_primary, .gMH8yW_root.gMH8yW_secondary {
  text-align: center;
  color: var(--white);
  font-family: Xfinity Brown TT, sans-serif;
  font-size: 1.125rem;
}

.gMH8yW_root.gMH8yW_primary {
  background-color: var(--white);
  color: var(--black);
  height: 62px;
  border-radius: 8px;
  padding: 0 3.3rem 5px;
  font-size: 18px;
  font-weight: bold;
  line-height: 62px;
}

.gMH8yW_root.gMH8yW_secondary {
  background-color: #0000;
}

.gMH8yW_root.gMH8yW_secondary.gMH8yW_outline {
  border: solid 2px var(--white);
  height: 62px;
  border-radius: 8px;
  padding: 0 2.5rem;
  font-weight: bold;
  line-height: 56px;
}

.gMH8yW_root.gMH8yW_secondary.gMH8yW_underline {
  border-bottom: 2px solid;
  padding-bottom: 5px;
}

@keyframes scale {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  45% {
    opacity: .7;
    transform: scale(.1);
  }

  80% {
    opacity: 1;
    transform: scale(1);
  }
}

.ball-pulse > div:nth-child(0) {
  animation: .75s cubic-bezier(.2, .68, .18, 1.08) -.36s infinite scale;
}

.ball-pulse > div:nth-child(1) {
  animation: .75s cubic-bezier(.2, .68, .18, 1.08) -.24s infinite scale;
}

.ball-pulse > div:nth-child(2) {
  animation: .75s cubic-bezier(.2, .68, .18, 1.08) -.12s infinite scale;
}

.ball-pulse > div:nth-child(3) {
  animation: .75s cubic-bezier(.2, .68, .18, 1.08) infinite scale;
}

.ball-pulse > div {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
}

@keyframes ball-pulse-sync {
  33% {
    transform: translateY(10px);
  }

  66% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.ball-pulse-sync > div:nth-child(0) {
  animation: .6s ease-in-out -.21s infinite ball-pulse-sync;
}

.ball-pulse-sync > div:nth-child(1) {
  animation: .6s ease-in-out -.14s infinite ball-pulse-sync;
}

.ball-pulse-sync > div:nth-child(2) {
  animation: .6s ease-in-out -.07s infinite ball-pulse-sync;
}

.ball-pulse-sync > div:nth-child(3) {
  animation: .6s ease-in-out infinite ball-pulse-sync;
}

.ball-pulse-sync > div {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
}

@keyframes ball-scale {
  0% {
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.ball-scale > div {
  width: 15px;
  height: 15px;
  height: 60px;
  width: 60px;
  width: 15px;
  height: 15px;
  height: 60px;
  width: 60px;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  animation: 1s ease-in-out infinite ball-scale;
  display: inline-block;
}

.ball-scale-random {
  width: 37px;
  height: 40px;
}

.ball-scale-random > div {
  width: 15px;
  height: 15px;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  animation: 1s ease-in-out infinite ball-scale;
  display: inline-block;
  position: absolute;
}

.ball-scale-random > div:nth-child(1) {
  margin-left: -7px;
  animation: 1s ease-in-out .2s infinite ball-scale;
}

.ball-scale-random > div:nth-child(3) {
  margin-top: 9px;
  margin-left: -2px;
  animation: 1s ease-in-out .5s infinite ball-scale;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ball-rotate {
  position: relative;
}

.ball-rotate > div {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  position: relative;
}

.ball-rotate > div:first-child {
  animation: 1s cubic-bezier(.7, -.13, .22, .86) infinite rotate;
}

.ball-rotate > div:before, .ball-rotate > div:after {
  width: 15px;
  height: 15px;
  content: "";
  opacity: .8;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  position: absolute;
}

.ball-rotate > div:before {
  top: 0;
  left: -28px;
}

.ball-rotate > div:after {
  top: 0;
  left: 25px;
}

@keyframes rotate {
  0% {
    transform: rotate(0)scale(1);
  }

  50% {
    transform: rotate(180deg)scale(.6);
  }

  100% {
    transform: rotate(360deg)scale(1);
  }
}

.ball-clip-rotate > div {
  width: 15px;
  height: 15px;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px solid #fff;
  border-bottom-color: #0000;
  border-radius: 100%;
  margin: 2px;
  animation: .75s linear infinite rotate;
  display: inline-block;
  background: none !important;
}

@keyframes scale {
  30% {
    transform: scale(.3);
  }

  100% {
    transform: scale(1);
  }
}

.ball-clip-rotate-pulse {
  position: relative;
  transform: translateY(-15px);
}

.ball-clip-rotate-pulse > div {
  border-radius: 100%;
  animation-fill-mode: both;
  position: absolute;
  top: 0;
  left: 0;
}

.ball-clip-rotate-pulse > div:first-child {
  height: 16px;
  width: 16px;
  background: #fff;
  animation: 1s cubic-bezier(.09, .57, .49, .9) infinite scale;
  top: 7px;
  left: -7px;
}

.ball-clip-rotate-pulse > div:last-child {
  width: 30px;
  height: 30px;
  background: none;
  border: 2px solid #fff;
  border-color: #fff #0000;
  animation: 1s cubic-bezier(.09, .57, .49, .9) infinite rotate;
  position: absolute;
  top: -2px;
  left: -16px;
}

.ball-clip-rotate-multiple {
  position: relative;
}

.ball-clip-rotate-multiple > div {
  height: 35px;
  width: 35px;
  border: 2px solid #0000;
  border-color: #0000 #fff;
  border-radius: 100%;
  animation: 1s ease-in-out infinite rotate;
  position: absolute;
  top: -20px;
  left: -20px;
}

.ball-clip-rotate-multiple > div:last-child {
  width: 15px;
  height: 15px;
  border-color: #fff #0000;
  animation-duration: .5s;
  animation-direction: reverse;
  display: inline-block;
  top: -10px;
  left: -10px;
}

@keyframes ball-scale-ripple {
  0% {
    opacity: 1;
    transform: scale(.1);
  }

  70% {
    opacity: .7;
    transform: scale(1);
  }

  100% {
    opacity: 0;
  }
}

.ball-scale-ripple > div {
  height: 50px;
  width: 50px;
  border: 2px solid #fff;
  border-radius: 100%;
  animation: 1s cubic-bezier(.21, .53, .56, .8) infinite ball-scale-ripple;
}

@keyframes ball-scale-ripple-multiple {
  0% {
    opacity: 1;
    transform: scale(.1);
  }

  70% {
    opacity: .7;
    transform: scale(1);
  }

  100% {
    opacity: 0;
  }
}

.ball-scale-ripple-multiple {
  position: relative;
  transform: translateY(-25px);
}

.ball-scale-ripple-multiple > div:nth-child(0) {
  animation-delay: -.8s;
}

.ball-scale-ripple-multiple > div:nth-child(1) {
  animation-delay: -.6s;
}

.ball-scale-ripple-multiple > div:nth-child(2) {
  animation-delay: -.4s;
}

.ball-scale-ripple-multiple > div:nth-child(3) {
  animation-delay: -.2s;
}

.ball-scale-ripple-multiple > div {
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  border-radius: 100%;
  animation: 1.25s cubic-bezier(.21, .53, .56, .8) infinite ball-scale-ripple-multiple;
  position: absolute;
  top: -2px;
  left: -26px;
}

@keyframes ball-beat {
  50% {
    opacity: .2;
    transform: scale(.75);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ball-beat > div {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  animation: .7s linear infinite ball-beat;
  display: inline-block;
}

.ball-beat > div:nth-child(2n-1) {
  animation-delay: -.35s !important;
}

@keyframes ball-scale-multiple {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  5% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.ball-scale-multiple {
  position: relative;
  transform: translateY(-30px);
}

.ball-scale-multiple > div:nth-child(2) {
  animation-delay: -.4s;
}

.ball-scale-multiple > div:nth-child(3) {
  animation-delay: -.2s;
}

.ball-scale-multiple > div {
  width: 15px;
  height: 15px;
  opacity: 0;
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 100%;
  margin: 0;
  animation: 1s linear infinite ball-scale-multiple;
  position: absolute;
  top: 0;
  left: -30px;
}

@keyframes ball-triangle-path-1 {
  33% {
    transform: translate(25px, -50px);
  }

  66% {
    transform: translate(50px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes ball-triangle-path-2 {
  33% {
    transform: translate(25px, 50px);
  }

  66% {
    transform: translate(-25px, 50px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes ball-triangle-path-3 {
  33% {
    transform: translate(-50px);
  }

  66% {
    transform: translate(-25px, -50px);
  }

  100% {
    transform: translate(0);
  }
}

.ball-triangle-path {
  position: relative;
  transform: translate(-29.994px, -37.5094px);
}

.ball-triangle-path > div:nth-child(1) {
  animation-name: ball-triangle-path-1;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.ball-triangle-path > div:nth-child(2) {
  animation-name: ball-triangle-path-2;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.ball-triangle-path > div:nth-child(3) {
  animation-name: ball-triangle-path-3;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.ball-triangle-path > div {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 100%;
  animation-fill-mode: both;
  position: absolute;
}

.ball-triangle-path > div:nth-of-type(1) {
  top: 50px;
}

.ball-triangle-path > div:nth-of-type(2) {
  left: 25px;
}

.ball-triangle-path > div:nth-of-type(3) {
  top: 50px;
  left: 50px;
}

@keyframes ball-pulse-rise-even {
  0% {
    transform: scale(1.1);
  }

  25% {
    transform: translateY(-30px);
  }

  50% {
    transform: scale(.4);
  }

  75% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes ball-pulse-rise-odd {
  0% {
    transform: scale(.4);
  }

  25% {
    transform: translateY(30px);
  }

  50% {
    transform: scale(1.1);
  }

  75% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0);
  }
}

.ball-pulse-rise > div {
  width: 15px;
  height: 15px;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(.15, .46, .9, .6);
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  display: inline-block;
}

.ball-pulse-rise > div:nth-child(2n) {
  animation-name: ball-pulse-rise-even;
}

.ball-pulse-rise > div:nth-child(2n-1) {
  animation-name: ball-pulse-rise-odd;
}

@keyframes ball-grid-beat {
  50% {
    opacity: .7;
  }

  100% {
    opacity: 1;
  }
}

.ball-grid-beat {
  width: 57px;
}

.ball-grid-beat > div:nth-child(1) {
  animation-duration: 1.27s;
  animation-delay: .44s;
}

.ball-grid-beat > div:nth-child(2) {
  animation-duration: 1.52s;
  animation-delay: .2s;
}

.ball-grid-beat > div:nth-child(3) {
  animation-duration: .61s;
  animation-delay: .14s;
}

.ball-grid-beat > div:nth-child(4) {
  animation-duration: .82s;
  animation-delay: .15s;
}

.ball-grid-beat > div:nth-child(5) {
  animation-duration: 1.24s;
  animation-delay: -.01s;
}

.ball-grid-beat > div:nth-child(6) {
  animation-duration: 1.35s;
  animation-delay: -.07s;
}

.ball-grid-beat > div:nth-child(7) {
  animation-duration: 1.44s;
  animation-delay: .29s;
}

.ball-grid-beat > div:nth-child(8) {
  animation-duration: 1.19s;
  animation-delay: .63s;
}

.ball-grid-beat > div:nth-child(9) {
  animation-duration: 1.48s;
  animation-delay: -.18s;
}

.ball-grid-beat > div {
  width: 15px;
  height: 15px;
  float: left;
  animation-name: ball-grid-beat;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  display: inline-block;
}

@keyframes ball-grid-pulse {
  0% {
    transform: scale(1);
  }

  50% {
    opacity: .7;
    transform: scale(.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ball-grid-pulse {
  width: 57px;
}

.ball-grid-pulse > div:nth-child(1) {
  animation-duration: .9s;
  animation-delay: .58s;
}

.ball-grid-pulse > div:nth-child(2) {
  animation-duration: .94s;
  animation-delay: 10ms;
}

.ball-grid-pulse > div:nth-child(3) {
  animation-duration: 1.43s;
  animation-delay: .25s;
}

.ball-grid-pulse > div:nth-child(4) {
  animation-duration: .74s;
  animation-delay: -.03s;
}

.ball-grid-pulse > div:nth-child(5) {
  animation-duration: .68s;
  animation-delay: .21s;
}

.ball-grid-pulse > div:nth-child(6) {
  animation-duration: 1.17s;
  animation-delay: .25s;
}

.ball-grid-pulse > div:nth-child(7) {
  animation-duration: 1.41s;
  animation-delay: .46s;
}

.ball-grid-pulse > div:nth-child(8) {
  animation-duration: 1.56s;
  animation-delay: 20ms;
}

.ball-grid-pulse > div:nth-child(9) {
  animation-duration: .78s;
  animation-delay: .13s;
}

.ball-grid-pulse > div {
  width: 15px;
  height: 15px;
  float: left;
  animation-name: ball-grid-pulse;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  display: inline-block;
}

@keyframes ball-spin-fade-loader {
  50% {
    opacity: .3;
    transform: scale(.4);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ball-spin-fade-loader {
  position: relative;
  top: -10px;
  left: -10px;
}

.ball-spin-fade-loader > div:nth-child(1) {
  animation: 1s linear -.96s infinite ball-spin-fade-loader;
  top: 25px;
  left: 0;
}

.ball-spin-fade-loader > div:nth-child(2) {
  animation: 1s linear -.84s infinite ball-spin-fade-loader;
  top: 17.0455px;
  left: 17.0455px;
}

.ball-spin-fade-loader > div:nth-child(3) {
  animation: 1s linear -.72s infinite ball-spin-fade-loader;
  top: 0;
  left: 25px;
}

.ball-spin-fade-loader > div:nth-child(4) {
  animation: 1s linear -.6s infinite ball-spin-fade-loader;
  top: -17.0455px;
  left: 17.0455px;
}

.ball-spin-fade-loader > div:nth-child(5) {
  animation: 1s linear -.48s infinite ball-spin-fade-loader;
  top: -25px;
  left: 0;
}

.ball-spin-fade-loader > div:nth-child(6) {
  animation: 1s linear -.36s infinite ball-spin-fade-loader;
  top: -17.0455px;
  left: -17.0455px;
}

.ball-spin-fade-loader > div:nth-child(7) {
  animation: 1s linear -.24s infinite ball-spin-fade-loader;
  top: 0;
  left: -25px;
}

.ball-spin-fade-loader > div:nth-child(8) {
  animation: 1s linear -.12s infinite ball-spin-fade-loader;
  top: 17.0455px;
  left: -17.0455px;
}

.ball-spin-fade-loader > div {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  position: absolute;
}

@keyframes ball-spin-loader {
  75% {
    opacity: .2;
  }

  100% {
    opacity: 1;
  }
}

.ball-spin-loader {
  position: relative;
}

.ball-spin-loader > span:nth-child(1) {
  animation: 2s linear .9s infinite ball-spin-loader;
  top: 45px;
  left: 0;
}

.ball-spin-loader > span:nth-child(2) {
  animation: 2s linear 1.8s infinite ball-spin-loader;
  top: 30.6818px;
  left: 30.6818px;
}

.ball-spin-loader > span:nth-child(3) {
  animation: 2s linear 2.7s infinite ball-spin-loader;
  top: 0;
  left: 45px;
}

.ball-spin-loader > span:nth-child(4) {
  animation: 2s linear 3.6s infinite ball-spin-loader;
  top: -30.6818px;
  left: 30.6818px;
}

.ball-spin-loader > span:nth-child(5) {
  animation: 2s linear 4.5s infinite ball-spin-loader;
  top: -45px;
  left: 0;
}

.ball-spin-loader > span:nth-child(6) {
  animation: 2s linear 5.4s infinite ball-spin-loader;
  top: -30.6818px;
  left: -30.6818px;
}

.ball-spin-loader > span:nth-child(7) {
  animation: 2s linear 6.3s infinite ball-spin-loader;
  top: 0;
  left: -45px;
}

.ball-spin-loader > span:nth-child(8) {
  animation: 2s linear 7.2s infinite ball-spin-loader;
  top: 30.6818px;
  left: -30.6818px;
}

.ball-spin-loader > div {
  width: 15px;
  height: 15px;
  background: green;
  border-radius: 100%;
  animation-fill-mode: both;
  position: absolute;
}

@keyframes ball-zig {
  33% {
    transform: translate(-15px, -30px);
  }

  66% {
    transform: translate(15px, -30px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes ball-zag {
  33% {
    transform: translate(15px, 30px);
  }

  66% {
    transform: translate(-15px, 30px);
  }

  100% {
    transform: translate(0);
  }
}

.ball-zig-zag {
  position: relative;
  transform: translate(-15px, -15px);
}

.ball-zig-zag > div {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px 2px 2px 15px;
  animation-fill-mode: both;
  position: absolute;
  top: 4px;
  left: -7px;
}

.ball-zig-zag > div:first-child {
  animation: .7s linear infinite ball-zig;
}

.ball-zig-zag > div:last-child {
  animation: .7s linear infinite ball-zag;
}

@keyframes ball-zig-deflect {
  17% {
    transform: translate(-15px, -30px);
  }

  34% {
    transform: translate(15px, -30px);
  }

  50% {
    transform: translate(0);
  }

  67% {
    transform: translate(15px, -30px);
  }

  84% {
    transform: translate(-15px, -30px);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes ball-zag-deflect {
  17% {
    transform: translate(15px, 30px);
  }

  34% {
    transform: translate(-15px, 30px);
  }

  50% {
    transform: translate(0);
  }

  67% {
    transform: translate(-15px, 30px);
  }

  84% {
    transform: translate(15px, 30px);
  }

  100% {
    transform: translate(0);
  }
}

.ball-zig-zag-deflect {
  position: relative;
  transform: translate(-15px, -15px);
}

.ball-zig-zag-deflect > div {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px 2px 2px 15px;
  animation-fill-mode: both;
  position: absolute;
  top: 4px;
  left: -7px;
}

.ball-zig-zag-deflect > div:first-child {
  animation: 1.5s linear infinite ball-zig-deflect;
}

.ball-zig-zag-deflect > div:last-child {
  animation: 1.5s linear infinite ball-zag-deflect;
}

@keyframes line-scale {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(.4);
  }

  100% {
    transform: scaleY(1);
  }
}

.line-scale > div:nth-child(1) {
  animation: 1s cubic-bezier(.2, .68, .18, 1.08) -.4s infinite line-scale;
}

.line-scale > div:nth-child(2) {
  animation: 1s cubic-bezier(.2, .68, .18, 1.08) -.3s infinite line-scale;
}

.line-scale > div:nth-child(3) {
  animation: 1s cubic-bezier(.2, .68, .18, 1.08) -.2s infinite line-scale;
}

.line-scale > div:nth-child(4) {
  animation: 1s cubic-bezier(.2, .68, .18, 1.08) -.1s infinite line-scale;
}

.line-scale > div:nth-child(5) {
  animation: 1s cubic-bezier(.2, .68, .18, 1.08) infinite line-scale;
}

.line-scale > div {
  width: 4px;
  height: 35px;
  background-color: #fff;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  display: inline-block;
}

@keyframes line-scale-party {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(.5);
  }

  100% {
    transform: scale(1);
  }
}

.line-scale-party > div:nth-child(1) {
  animation-duration: .83s;
  animation-delay: -.09s;
}

.line-scale-party > div:nth-child(2) {
  animation-duration: .64s;
  animation-delay: .33s;
}

.line-scale-party > div:nth-child(3) {
  animation-duration: .39s;
  animation-delay: .32s;
}

.line-scale-party > div:nth-child(4) {
  animation-duration: .52s;
  animation-delay: .47s;
}

.line-scale-party > div {
  width: 4px;
  height: 35px;
  animation-name: line-scale-party;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  animation-delay: 0;
  background-color: #fff;
  border-radius: 2px;
  margin: 2px;
  display: inline-block;
}

@keyframes line-scale-pulse-out {
  0% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(.4);
  }

  100% {
    transform: scaleY(1);
  }
}

.line-scale-pulse-out > div {
  width: 4px;
  height: 35px;
  background-color: #fff;
  border-radius: 2px;
  margin: 2px;
  animation: .9s cubic-bezier(.85, .25, .37, .85) -.6s infinite line-scale-pulse-out;
  display: inline-block;
}

.line-scale-pulse-out > div:nth-child(2), .line-scale-pulse-out > div:nth-child(4) {
  animation-delay: -.4s !important;
}

.line-scale-pulse-out > div:nth-child(1), .line-scale-pulse-out > div:nth-child(5) {
  animation-delay: -.2s !important;
}

@keyframes line-scale-pulse-out-rapid {
  0% {
    transform: scaleY(1);
  }

  80% {
    transform: scaleY(.3);
  }

  90% {
    transform: scaleY(1);
  }
}

.line-scale-pulse-out-rapid > div {
  width: 4px;
  height: 35px;
  background-color: #fff;
  border-radius: 2px;
  margin: 2px;
  animation: .9s cubic-bezier(.11, .49, .38, .78) -.5s infinite line-scale-pulse-out-rapid;
  display: inline-block;
}

.line-scale-pulse-out-rapid > div:nth-child(2), .line-scale-pulse-out-rapid > div:nth-child(4) {
  animation-delay: -.25s !important;
}

.line-scale-pulse-out-rapid > div:nth-child(1), .line-scale-pulse-out-rapid > div:nth-child(5) {
  animation-delay: 0s !important;
}

@keyframes line-spin-fade-loader {
  50% {
    opacity: .3;
  }

  100% {
    opacity: 1;
  }
}

.line-spin-fade-loader {
  position: relative;
  top: -10px;
  left: -4px;
}

.line-spin-fade-loader > div:nth-child(1) {
  animation: 1.2s ease-in-out -.84s infinite line-spin-fade-loader;
  top: 20px;
  left: 0;
}

.line-spin-fade-loader > div:nth-child(2) {
  animation: 1.2s ease-in-out -.72s infinite line-spin-fade-loader;
  top: 13.6364px;
  left: 13.6364px;
  transform: rotate(-45deg);
}

.line-spin-fade-loader > div:nth-child(3) {
  animation: 1.2s ease-in-out -.6s infinite line-spin-fade-loader;
  top: 0;
  left: 20px;
  transform: rotate(90deg);
}

.line-spin-fade-loader > div:nth-child(4) {
  animation: 1.2s ease-in-out -.48s infinite line-spin-fade-loader;
  top: -13.6364px;
  left: 13.6364px;
  transform: rotate(45deg);
}

.line-spin-fade-loader > div:nth-child(5) {
  animation: 1.2s ease-in-out -.36s infinite line-spin-fade-loader;
  top: -20px;
  left: 0;
}

.line-spin-fade-loader > div:nth-child(6) {
  animation: 1.2s ease-in-out -.24s infinite line-spin-fade-loader;
  top: -13.6364px;
  left: -13.6364px;
  transform: rotate(-45deg);
}

.line-spin-fade-loader > div:nth-child(7) {
  animation: 1.2s ease-in-out -.12s infinite line-spin-fade-loader;
  top: 0;
  left: -20px;
  transform: rotate(90deg);
}

.line-spin-fade-loader > div:nth-child(8) {
  animation: 1.2s ease-in-out infinite line-spin-fade-loader;
  top: 13.6364px;
  left: -13.6364px;
  transform: rotate(45deg);
}

.line-spin-fade-loader > div {
  width: 4px;
  height: 35px;
  width: 5px;
  height: 15px;
  background-color: #fff;
  border-radius: 2px;
  margin: 2px;
  animation-fill-mode: both;
  position: absolute;
}

@keyframes triangle-skew-spin {
  25% {
    transform: perspective(100px)rotateX(180deg)rotateY(0);
  }

  50% {
    transform: perspective(100px)rotateX(180deg)rotateY(180deg);
  }

  75% {
    transform: perspective(100px)rotateX(0)rotateY(180deg);
  }

  100% {
    transform: perspective(100px)rotateX(0)rotateY(0);
  }
}

.triangle-skew-spin > div {
  width: 0;
  height: 0;
  border-bottom: 20px solid #fff;
  border-left: 20px solid #0000;
  border-right: 20px solid #0000;
  animation: 3s cubic-bezier(.09, .57, .49, .9) infinite triangle-skew-spin;
}

@keyframes square-spin {
  25% {
    transform: perspective(100px)rotateX(180deg)rotateY(0);
  }

  50% {
    transform: perspective(100px)rotateX(180deg)rotateY(180deg);
  }

  75% {
    transform: perspective(100px)rotateX(0)rotateY(180deg);
  }

  100% {
    transform: perspective(100px)rotateX(0)rotateY(0);
  }
}

.square-spin > div {
  width: 50px;
  height: 50px;
  background: #fff;
  border: 1px solid red;
  animation: 3s cubic-bezier(.09, .57, .49, .9) infinite square-spin;
}

@keyframes rotate_pacman_half_up {
  0% {
    transform: rotate(270deg);
  }

  50% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes rotate_pacman_half_down {
  0% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(90deg);
  }
}

@keyframes pacman-balls {
  75% {
    opacity: .7;
  }

  100% {
    transform: translate(-100px, -6.25px);
  }
}

.pacman {
  position: relative;
}

.pacman > div:nth-child(2) {
  animation: 1s linear -.99s infinite pacman-balls;
}

.pacman > div:nth-child(3) {
  animation: 1s linear -.66s infinite pacman-balls;
}

.pacman > div:nth-child(4) {
  animation: 1s linear -.33s infinite pacman-balls;
}

.pacman > div:nth-child(5) {
  animation: 1s linear infinite pacman-balls;
}

.pacman > div:first-of-type {
  width: 0;
  height: 0;
  border: 25px solid #fff;
  border-right-color: #0000;
  border-radius: 25px;
  animation: .5s infinite rotate_pacman_half_up;
  position: relative;
  left: -30px;
}

.pacman > div:nth-child(2) {
  width: 0;
  height: 0;
  border: 25px solid #fff;
  border-right-color: #0000;
  border-radius: 25px;
  margin-top: -50px;
  animation: .5s infinite rotate_pacman_half_down;
  position: relative;
  left: -30px;
}

.pacman > div:nth-child(3), .pacman > div:nth-child(4), .pacman > div:nth-child(5), .pacman > div:nth-child(6) {
  width: 15px;
  height: 15px;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px;
  position: absolute;
  top: 25px;
  left: 70px;
  transform: translate(0, -6.25px);
}

@keyframes cube-transition {
  25% {
    transform: translateX(50px)scale(.5)rotate(-90deg);
  }

  50% {
    transform: translate(50px, 50px)rotate(-180deg);
  }

  75% {
    transform: translateY(50px)scale(.5)rotate(-270deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.cube-transition {
  position: relative;
  transform: translate(-25px, -25px);
}

.cube-transition > div {
  width: 10px;
  height: 10px;
  background-color: #fff;
  animation: 1.6s ease-in-out infinite cube-transition;
  position: absolute;
  top: -5px;
  left: -5px;
}

.cube-transition > div:last-child {
  animation-delay: -.8s;
}

@keyframes spin-rotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.semi-circle-spin {
  width: 35px;
  height: 35px;
  position: relative;
  overflow: hidden;
}

.semi-circle-spin > div {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#0000 0% 70%, #fff 30% 100%);
  border-width: 0;
  border-radius: 100%;
  animation: .6s linear infinite spin-rotate;
  position: absolute;
}

@keyframes bar-progress {
  0% {
    opacity: 1;
    transform: scaleY(20%);
  }

  25% {
    opacity: .7;
    transform: translateX(6%)scaleY(10%);
  }

  50% {
    opacity: 1;
    transform: translateX(20%)scaleY(20%);
  }

  75% {
    opacity: .7;
    transform: translateX(6%)scaleY(10%);
  }

  100% {
    opacity: 1;
    transform: scaleY(20%);
  }
}

.bar-progress {
  width: 30%;
  height: 12px;
}

.bar-progress > div {
  width: 20%;
  height: 12px;
  opacity: 1;
  background-color: #fff;
  border-radius: 10px;
  animation: 3s cubic-bezier(.57, .1, .44, .93) infinite bar-progress;
  position: relative;
}

@keyframes bar-swing {
  0% {
    left: 0;
  }

  50% {
    left: 70%;
  }

  100% {
    left: 0;
  }
}

.bar-swing {
  width: 30%;
  height: 8px;
}

.bar-swing > div {
  width: 30%;
  height: 8px;
  background-color: #fff;
  border-radius: 10px;
  animation: 1.5s infinite bar-swing;
  position: relative;
}

@keyframes bar-swing-container {
  0% {
    left: 0;
    transform: translateX(0);
  }

  50% {
    left: 70%;
    transform: translateX(-4px);
  }

  100% {
    left: 0;
    transform: translateX(0);
  }
}

.bar-swing-container {
  width: 20%;
  height: 8px;
  position: relative;
}

.bar-swing-container div:nth-child(1) {
  width: 100%;
  height: 12px;
  background-color: #fff3;
  border-radius: 10px;
  position: absolute;
}

.bar-swing-container div:nth-child(2) {
  width: 30%;
  height: 8px;
  background-color: #fff;
  border-radius: 10px;
  margin: 2px 2px 0;
  animation: 2s cubic-bezier(.91, .35, .12, .6) infinite bar-swing-container;
  position: absolute;
}

.sk-spinner {
  color: #333;
}

.sk-spinner > div {
  background-color: currentColor;
}

.ball-triangle-path > div, .ball-scale-ripple-multiple > div, .ball-scale-ripple > div {
  background-color: initial;
  border-color: currentColor;
}

.ball-clip-rotate > div {
  background-color: initial;
  border-top-color: currentColor;
  border-left-color: currentColor;
  border-right-color: currentColor;
}

.ball-clip-rotate-pulse > div:first-child {
  background-color: currentColor;
}

.ball-clip-rotate-pulse > div:last-child {
  background-color: initial;
  border-top-color: currentColor;
  border-bottom-color: currentColor;
}

.ball-clip-rotate-multiple > div:first-child {
  background-color: initial;
  border-left-color: currentColor;
  border-right-color: currentColor;
}

.ball-clip-rotate-multiple > div:last-child {
  background-color: initial;
  border-top-color: currentColor;
  border-bottom-color: currentColor;
}

.triangle-skew-spin > div {
  background-color: initial;
  border-bottom-color: currentColor;
}

.pacman > div:nth-child(1), .pacman > div:nth-child(2) {
  background-color: initial;
  border-top-color: currentColor;
  border-bottom-color: currentColor;
  border-left-color: currentColor;
}

.pacman > div:nth-child(3), .pacman > div:nth-child(4), .pacman > div:nth-child(5) {
  background-color: currentColor;
}

@keyframes sk-fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.sk-fade-in {
  -ms-animation: sk-fade-in 2s;
  animation: 2s sk-fade-in;
}

.sk-fade-in-half-second {
  -ms-animation: sk-fade-in 1s;
  animation: 1s sk-fade-in;
}

.sk-fade-in-quarter-second {
  -ms-animation: sk-fade-in .5s;
  animation: .5s sk-fade-in;
}

.sk-chasing-dots {
  width: 27px;
  height: 27px;
  animation: 2s linear infinite sk-rotate;
  position: relative;
}

.sk-chasing-dots > div {
  width: 60%;
  height: 60%;
  background-color: currentColor;
  border-radius: 100%;
  animation: 2s ease-in-out infinite sk-bounce;
  display: inline-block;
  position: absolute;
  top: 0;
}

.sk-chasing-dots > div:last-child {
  animation-delay: -1s;
  top: auto;
  bottom: 0;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.sk-circle {
  width: 22px;
  height: 22px;
  position: relative;
}

.sk-circle > div {
  background-color: initial;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.sk-circle > div:before {
  content: "";
  width: 20%;
  height: 20%;
  background-color: currentColor;
  border-radius: 100%;
  margin: 0 auto;
  animation: 1.2s ease-in-out infinite both sk-bouncedelay;
  display: block;
}

.sk-circle > div:nth-child(2) {
  transform: rotate(30deg);
}

.sk-circle > div:nth-child(3) {
  transform: rotate(60deg);
}

.sk-circle > div:nth-child(4) {
  transform: rotate(90deg);
}

.sk-circle > div:nth-child(5) {
  transform: rotate(120deg);
}

.sk-circle > div:nth-child(6) {
  transform: rotate(150deg);
}

.sk-circle > div:nth-child(7) {
  transform: rotate(180deg);
}

.sk-circle > div:nth-child(8) {
  transform: rotate(210deg);
}

.sk-circle > div:nth-child(9) {
  transform: rotate(240deg);
}

.sk-circle > div:nth-child(10) {
  transform: rotate(270deg);
}

.sk-circle > div:nth-child(11) {
  transform: rotate(300deg);
}

.sk-circle > div:nth-child(12) {
  transform: rotate(330deg);
}

.sk-circle > div:nth-child(2):before {
  animation-delay: -1.1s;
}

.sk-circle > div:nth-child(3):before {
  animation-delay: -1s;
}

.sk-circle > div:nth-child(4):before {
  animation-delay: -.9s;
}

.sk-circle > div:nth-child(5):before {
  animation-delay: -.8s;
}

.sk-circle > div:nth-child(6):before {
  animation-delay: -.7s;
}

.sk-circle > div:nth-child(7):before {
  animation-delay: -.6s;
}

.sk-circle > div:nth-child(8):before {
  animation-delay: -.5s;
}

.sk-circle > div:nth-child(9):before {
  animation-delay: -.4s;
}

.sk-circle > div:nth-child(10):before {
  animation-delay: -.3s;
}

.sk-circle > div:nth-child(11):before {
  animation-delay: -.2s;
}

.sk-circle > div:nth-child(12):before {
  animation-delay: -.1s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.sk-cube-grid {
  width: 27px;
  height: 27px;
}

.sk-cube-grid > div {
  width: 33%;
  height: 33%;
  float: left;
  background-color: currentColor;
  animation: 1.3s ease-in-out infinite sk-scaleDelay;
}

.sk-cube-grid > div:nth-child(1) {
  animation-delay: .2s;
}

.sk-cube-grid > div:nth-child(2) {
  animation-delay: .3s;
}

.sk-cube-grid > div:nth-child(3) {
  animation-delay: .4s;
}

.sk-cube-grid > div:nth-child(4) {
  animation-delay: .1s;
}

.sk-cube-grid > div:nth-child(5) {
  animation-delay: .2s;
}

.sk-cube-grid > div:nth-child(6) {
  animation-delay: .3s;
}

.sk-cube-grid > div:nth-child(7) {
  animation-delay: 0s;
}

.sk-cube-grid > div:nth-child(8) {
  animation-delay: .1s;
}

.sk-cube-grid > div:nth-child(9) {
  animation-delay: .2s;
}

@-webkit-keyframes sk-scaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3d(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3d(0, 0, 1);
  }
}

@keyframes sk-scaleDelay {
  0%, 70%, 100% {
    transform: scale3d(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(0, 0, 1);
  }
}

.sk-double-bounce {
  width: 27px;
  height: 27px;
  position: relative;
}

.sk-double-bounce > div {
  width: 100%;
  height: 100%;
  opacity: .6;
  background-color: currentColor;
  border-radius: 50%;
  animation: 2s ease-in-out infinite sk-bounce;
  position: absolute;
  top: 0;
  left: 0;
}

.sk-double-bounce > div:last-child {
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.sk-folding-cube {
  width: 27px;
  height: 27px;
  position: relative;
  transform: rotateZ(45deg);
}

.sk-folding-cube > div {
  background-color: initial;
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}

.sk-folding-cube > div:before {
  content: "";
  width: 100%;
  height: 100%;
  transform-origin: 100% 100%;
  background-color: currentColor;
  animation: 2.4s linear infinite both sk-foldCubeAngle;
  position: absolute;
  top: 0;
  left: 0;
}

.sk-folding-cube > div:nth-child(2) {
  transform: scale(1.1)rotateZ(90deg);
}

.sk-folding-cube > div:nth-child(4) {
  transform: scale(1.1)rotateZ(180deg);
}

.sk-folding-cube > div:nth-child(3) {
  transform: scale(1.1)rotateZ(270deg);
}

.sk-folding-cube > div:nth-child(2):before {
  animation-delay: .3s;
}

.sk-folding-cube > div:nth-child(4):before {
  animation-delay: .6s;
}

.sk-folding-cube > div:nth-child(3):before {
  animation-delay: .9s;
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    opacity: 0;
    transform: perspective(140px)rotateX(-180deg);
  }

  25%, 75% {
    opacity: 1;
    transform: perspective(140px)rotateX(0);
  }

  90%, 100% {
    opacity: 0;
    transform: perspective(140px)rotateY(180deg);
  }
}

.sk-pulse > div {
  width: 27px;
  height: 27px;
  background-color: currentColor;
  border-radius: 100%;
  animation: 1s ease-in-out infinite sk-scaleout;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
  }
}

@keyframes sk-scaleout {
  0% {
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.sk-rotating-plane > div {
  width: 27px;
  height: 27px;
  background-color: currentColor;
  animation: 1.2s ease-in-out infinite sk-rotateplane;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }

  50% {
    -webkit-transform: perspective(120px)rotateY(180deg);
  }

  100% {
    -webkit-transform: perspective(120px)rotateY(180deg)rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px)rotateX(0)rotateY(0);
  }

  50% {
    transform: perspective(120px)rotateX(-180.1deg)rotateY(0);
  }

  100% {
    transform: perspective(120px)rotateX(-180deg)rotateY(-179.9deg);
  }
}

.sk-three-bounce {
  height: 18px;
}

.sk-three-bounce > div {
  width: 18px;
  height: 18px;
  background-color: currentColor;
  border-radius: 100%;
  animation: 1.4s ease-in-out infinite both sk-bouncedelay;
  display: inline-block;
}

.sk-three-bounce > div:first-child {
  animation-delay: -.32s;
}

.sk-three-bounce > div:nth-child(2) {
  animation-delay: -.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.sk-wandering-cubes {
  width: 52px;
  height: 52px;
  position: relative;
}

.sk-wandering-cubes > div {
  width: 10px;
  height: 10px;
  background-color: currentColor;
  animation: 1.8s ease-in-out infinite sk-cubemove;
  position: absolute;
  top: 0;
  left: 0;
}

.sk-wandering-cubes > div:last-child {
  animation-delay: -.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px)rotate(-90deg)scale(.5);
  }

  50% {
    -webkit-transform: translateX(42px)translateY(42px)rotate(-180deg);
  }

  75% {
    -webkit-transform: translateX(0)translateY(42px)rotate(-270deg)scale(.5);
  }

  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px)rotate(-90deg)scale(.5);
  }

  50% {
    transform: translateX(42px)translateY(42px)rotate(-179deg);
  }

  50.1% {
    transform: translateX(42px)translateY(42px)rotate(-180deg);
  }

  75% {
    transform: translateX(0)translateY(42px)rotate(-270deg)scale(.5);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.sk-wave {
  width: 30px;
  height: 27px;
}

.sk-wave > div {
  height: 100%;
  width: 6px;
  background-color: currentColor;
  animation: 1.2s ease-in-out infinite sk-stretchdelay;
  display: inline-block;
}

.sk-wave > div:nth-child(2) {
  animation-delay: -1.1s;
}

.sk-wave > div:nth-child(3) {
  animation-delay: -1s;
}

.sk-wave > div:nth-child(4) {
  animation-delay: -.9s;
}

.sk-wave > div:nth-child(5) {
  animation-delay: -.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(.4);
  }

  20% {
    transform: scaleY(1);
  }
}

.sk-wordpress > div {
  width: 27px;
  height: 27px;
  background-color: currentColor;
  border-radius: 27px;
  animation: 1s linear infinite sk-inner-circle;
  display: inline-block;
  position: relative;
}

.sk-wordpress > div:after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
}

@-webkit-keyframes sk-inner-circle {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-inner-circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

:root {
  --black: #000;
  --white: #fff;
  --blue: #1f69ff;
  --red: #e6004d;
  --orange: #e64f00;
  --purple: #714cf6;
  --dark-purple: #6138f5;
  --light-purple: #b29efa;
  --pastel-purple: #c1b1fb;
  --light-green: #02c684;
  --dark-green: #008558;
  --dark-light-purple: color. mix(#000, #6138f5, 85%);
  --light-light-purple: color. mix(#fff, #6138f5, 20%);
  --light-dark-green: color. mix(#fff, #008558, 50%);
  --light-dark-purple: color. mix(#fff, #6138f5, 50%);
  --dark-pastel-purple: color. mix(#000, #c1b1fb, 85%);
  --dark-dark-purple: color. mix(#000, #6138f5, 85%);
  --light-blue: #86adfc;
  --xfinity-purple: #5a23b9;
}

.root_e0309b {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.root_e0309b h1 {
  color: var(--white);
  font-family: Xfinity Brown TT, sans-serif;
}

.root_e0309b .sk-spinner {
  width: 50px;
  height: 50px;
  color: var(--white);
  margin-top: 32px;
}

/*# sourceMappingURL=index.875f7ec4.css.map */
