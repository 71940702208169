$black: var(--black);
$white: var(--white);
$blue: var(--blue);
$red: var(--red);
$orange: var(--orange);
$purple: var(--purple);
$dark-purple: var(--dark-purple);
$light-purple: var(--light-purple);
$pastel-purple: var(--pastel-purple);
$light-green: var(--light-green);
$dark-green: var(--dark-green);
$dark-light-purple: var(--dark-light-purple);
$light-light-purple: var(--light-light-purple);
$light-dark-green: var(--light-dark-green);
$light-dark-purple: var(--light-dark-purple);
$dark-pastel-purple: var(--dark-pastel-purple);
$dark-dark-purple: var(--dark-dark-purple);
$light-blue: var(--light-blue);
$xfinity-purple: var(--xfinity-purple);

:root {
	--black: #000000;
	--white: #ffffff;
	--blue: #1f69ff;
	--red: #e6004d;
	--orange: #e64f00;
	--purple: #714cf6;
	--dark-purple: #6138f5;
	--light-purple: #b29efa;
	--pastel-purple: #c1b1fb;
	--light-green: #02c684;
	--dark-green: #008558;
	--dark-light-purple: color.mix(#000000, #6138f5, 85%);
	--light-light-purple: color.mix(#ffffff, #6138f5, 20%);
	--light-dark-green: color.mix(#ffffff, #008558, 50%);
	--light-dark-purple: color.mix(#ffffff, #6138f5, 50%);
	--dark-pastel-purple: color.mix(#000000, #c1b1fb, 85%);
	--dark-dark-purple: color.mix(#000000, #6138f5, 85%);
	--light-blue: #86adfc;
	--xfinity-purple: #5A23B9;
}

$font: 'Xfinity Brown TT', sans-serif;

@function z-index($type, $level) {
	@if $type == 'background' {
		$level: $level + 100;
	} @else if $type == 'content' {
		$level: $level + 200;
	} @else if $type == 'modal' {
		$level: $level + 300;
	}

	@return $level;
}

@mixin half-circle {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	@include z-index('background', 2);
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.halfCircleImage {
		content: url(/src/assets/football/images/half-circle.svg);
		width: 100%;
		flex-grow: 0;
	}

	.halfCircleSpacer {
		background-color: $purple;
		flex-grow: 1;
	}
}

@mixin z-index($type, $level) {
	z-index: z-index($type, $level);
}
