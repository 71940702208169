@import '~src/assets/fonts/fonts.css';

:root {
	--window-height: 100vh;

	&.basketball {
		.soccer,
		.football {
			display: none;
			p {
				font-family: 'Xfinity Brown TT', sans-serif;
				font-size: 28px;
				font-weight: 400;
				line-height: 34px;
				letter-spacing: -0.5px;
				text-align: center;				
				color: #fff;
			}

			a {
				font-family: 'Xfinity Brown TT', sans-serif;
				font-size: 18px;
				font-weight: 400;
				line-height: 22px;				
				text-align: center;				
				color: #fff;
				display: block;
				margin: 32px auto 36px;
				cursor: pointer;
			}
		}

		.soccer-icon,
		.football-icon {
			display: none;
		}

		.basketball-icon{
			position:absolute;
			top: -20px;
			left: 20px;
			width: 40px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	&.soccer {
		.basketball,
		.football {
			display: none;
			p {
				font-family: 'Xfinity Brown TT', sans-serif;
				font-size: 28px;
				font-weight: 400;
				line-height: 34px;
				letter-spacing: -0.5px;
				text-align: center;				
				color: #fff;
			}

			a {
				font-family: 'Xfinity Brown TT', sans-serif;
				font-size: 18px;
				font-weight: 400;
				line-height: 22px;				
				text-align: center;				
				color: #fff;
				display: block;
				margin: 32px auto 36px;
				cursor: pointer;
			}
		}

		.basketball-icon,
		.football-icon {
			display: none;
		}

		.soccer-icon{
			position:absolute;
			top: -20px;
			left: 20px;
			width: 40px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	&.football {
		.soccer,
		.basketball {
			display: none;
			p {
				font-family: 'Xfinity Brown TT', sans-serif;
				font-size: 28px;
				font-weight: 400;
				line-height: 34px;
				letter-spacing: -0.5px;
				text-align: center;				
				color: #fff;
			}

			a {
				font-family: 'Xfinity Brown TT', sans-serif;
				font-size: 18px;
				font-weight: 400;
				line-height: 22px;				
				text-align: center;				
				color: #fff;
				display: block;
				margin: 32px auto 36px;
				cursor: pointer;
			}
		}

		.soccer-icon,
		.basketball-icon {
			display: none;
		}

		.football-icon{
			position:absolute;
			top: -20px;
			left: 20px;
			width: 40px;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	&.basketball,
	.basketballScope,
	&.football,
	.footballScope,
	&.soccer,
	.soccerScope {
		//--background-image: url(/src/assets/basketball/images/grain.png);
		--background-image: url(/src/assets/images/purple-gradient-mobile.png);
		--field-background: var(--dark-purple);
		--field-outline: var(--light-purple);
		--leaderboard-offers-background: var(--dark-purple);
		--leaderboard-offers-color: white;
	}

	&.basketball,
	.basketballScope {
		--logo: url(/src/assets/basketball/images/we-all-ball.png);
		--ready-ball: url(/src/assets/basketball/images/ball-ar.png);
		--ready-ball-offset: calc(-1 * min(100vw, 420px) * 0.15);
		--leaderboard-ball: url(/src/assets/basketball/images/basketball.png);
		--leaderboard-half-circle: url(/src/assets/basketball/images/half-circle.png);
	}

	&.football,
	.footballScope {
		--logo: url(/src/assets/football/images/kick-it.png);
		--ready-ball: url(/src/assets/football/images/ball-ar.png);
		--ready-ball-offset: 0px;
		--leaderboard-ball: url(/src/assets/football/images/football.png);
		--leaderboard-half-circle: url(/src/assets/football/images/half-circle.png);
	}

	&.soccer,
	.soccerScope {
		--logo: url(/src/assets/soccer/images/goals-logo.png);
		--ready-ball: url(/src/assets/soccer/images/ball-ar.png);
		--ready-ball-offset: calc(-1 * min(100vw, 420px) * 0.15);
		--leaderboard-ball: url(/src/assets/soccer/images/soccer-ball.png);
		--leaderboard-half-circle: url(/src/assets/soccer/images/half-circle.png);
	}
}

.Field-Input {
	input {
		appearance: none;
		-webkit-appearance: none;
		border: solid 1px;
		height: 25px;
		&[type='text'],
		[type='select'],
		&[type='number'] {
			border-radius: 8px;
			padding: 12px 16px 13px;
		}
	}
}

// Reset
* {
	margin: 0;
	padding: 0;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	outline-style: none;
	-webkit-touch-callout: none;
}

body {
	background: var(--background-image);
	//background-size: 128px 128px;
	background-repeat: no-repeat;
	background-position: left top;
	background-size:cover;
}
